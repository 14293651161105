export const LookupHeaders = {
  Gender: 'GENDER',
  DaysOfWeek: 'DAYS_OF_THE_WEEK',
  CancellationReasons: 'ORDER_CANCELLATION_REASONS',
  OrderPauseReasons: 'ORDER_PAUSE_REASONS',
  FffDepartments: 'FFF_DEPARTMENTS',
  FrozenFoodInventoryTypes: 'FROZEN_FOOD_INVENTORY_TYPES',
  CancelSubscriptionReasons: 'CANCEL_SUBSCRIPTION_REASONS',
  AddCreditPackingErrorDetails: 'PACKING_ERROR_ADD_CREDIT_ADDITIONAL_DETAIL',
  VideoLibraryItemCategory: 'VIDEO_LIBRARY_ITEM_CATEGORY',
  FoodBoxSectionType: 'FOODBOX_SECTION_TYPE',
  FoodBoxPortionSize: 'FOODBOX_PORTION_SIZE',
  FoodBoxDietaryPreference: 'FOODBOX_DIETARY_PREFERENCE',
  FoodBoxFilter: 'FOODBOX_FILTER',
  FoodBoxPreference: 'FOODBOX_PREFERENCE',
  FoodBoxCalorieRange: 'FOODBOX_CALORIE_RANGE',
  FoodBoxGoalProductType: 'FOODBOX_PRODUCT_TYPE',
  FoodBoxGoalRecipeType: 'FOODBOX_RECIPE_TYPE',
  FoodBoxDietaryPreferenceFilterType: 'FOODBOX_DIETARY_PREFERENCE_FILTER_TYPE',
}

export const CancellationReasonLookups = {
  REACHED_MY_GOAL: "REACHED_MY_GOAL",
  MOVING_AWAY: "MOVING_AWAY",
  TOO_EXPENSIVE: "TOO_EXPENSIVE",
  NOT_ENJOYING_FOOD: "NOT_ENJOYING_FOOD",
  SERVICE_NOT_ACCOMMODATE_DIETARY_REQUIREMENTS: "SERVICE_NOT_ACCOMODATE_DIETARY_REQUIREMENTS",
  ERRORS_WITH_SERVICE: "ERRORS_WITH_SERVICE",
  NO_SUITABLE_DELIVERY_LOCATION: "NO_SUITABLE_DELIVERY_LOCATION",
  NOT_EATING_ALL_MEALS: "NOT_EATING_ALL_MEALS",
  SERVICE_NOT_FIT_WITH_LIFESTYLE: "SERVICE_NOT_FIT_WITH_LIFESTYLE",
  NOT_KNOWING_PLAN_WILL_AUTO_RENEW: "NOT_KNOWING_PLAN_WILL_AUTO_RENEW",
  OTHER: "OTHER",
}

export const CancelSubscriptionReasonLookups = {
  REACHED_MY_GOAL: "REACHED_MY_GOAL",
  NOT_EATING_ALL_MEALS: "NOT_EATING_ALL_MEALS",
  SERVICE_NOT_FIT_WITH_LIFESTYLE: "SERVICE_NOT_FIT_WITH_LIFESTYLE",
  TOO_EXPENSIVE: "TOO_EXPENSIVE",
  MOVING_AWAY: "MOVING_AWAY",
  INCONVENIENCE_WITH_DELIVERY: "INCONVENIENCE_WITH_DELIVERY",
  AUTO_RENEW_TO_BE_DECIDED_LATER: "AUTO_RENEW_TO_BE_DECIDED_LATER",
  RENEW_WITH_DIFFERENT_PLAN: "RENEW_WITH_DIFFERENT_PLAN",
  ERRORS_WITH_SERVICE: "ERRORS_WITH_SERVICE",
  NOT_ENJOYING_FOOD: "NOT_ENJOYING_FOOD",
  SERVICE_NOT_ACCOMMODATE_DIETARY_REQUIREMENTS: "SERVICE_NOT_ACCOMODATE_DIETARY_REQUIREMENTS",
  OTHER: "OTHER",
}

export const AddCreditDepartmentLookups = {
  COURIER_COMPANY_ERROR: "COURIER_COMPANY_ERROR",
  CUSTOMER_SERVICE_ERROR: "CUSTOMER_SERVICE_ERROR",
  FOOD_QUALITY: "FOOD_QUALITY",
  FILE_ERROR: "FILE_ERROR",
  PACKING_ERROR: "PACKING_ERROR",
  HEALTH_AND_SAFETY_ISSUE: "HEALTH_AND_SAFETY_ISSUE",
  EXPECTATIONS_NOT_MANAGED: "EXPECTATIONS_NOT_MANAGED",
  WEBSITE_ERROR: "WEBSITE_ERROR",
  OPERATIONS: "OPERATIONS",
  PROMOTIONAL: "PROMOTIONAL",
  SALES_ERROR: "SALES_ERROR",
}

export const FrozenFoodInventoryTypeLookups = {
  Meal: 'MEAL',
}

export const MealGenLookupHeaders = {
  MealDays: 'MEAL_DAY',
  IngredientCategories: 'INGREDIENT_CATEGORY',
  KitchenSections: 'KITCHEN_SECTION'
}

export const FoodBoxDietaryPreferenceLookups = {
  STANDARD: 'STANDARD'
}
