import moment from "moment";
import { DATE_FORMAT_ISO } from "../constants";
import FeaturGroupCodes from "../constants/feature-codes.constants";
import { DiscountCode, DiscountCodeInfo, DiscountCodePlatform, DiscountCodeSearchFilter, DiscountCodeSearchResults, DiscountCodeType, DiscountCodeUsageDto, DiscountSignUpDetailsDto, UpdateTokensFormValues } from "../types/discount-code.types";
import { FeatureRegistry } from "../types/feature-store.types";
import { CustomerOnBoardedType, MainOrderType } from "../types/fff.enums";
import { PaymentOption } from "../types/order-dto.types";
import { EntitySaveResult } from "../types/shared-dto.types";
import { featureRegistryService } from "./feature-registry.service";
import { apiService } from "./shared/api.service";


export const discountCodeService = {
  createDiscountCode,
  DeleteDiscountCode,
  getEmptyDiscountCodeObj,
  getDiscountCode,
  loadSeasonalDiscountCodes,
  updateDiscountCode,
  getDiscountCodeInfo,
  getDiscountValueForAmount,
  getDiscountCodeUsageListById,
  updateTokensByType,
  validateDiscountCodeApplicability,
  getValidatedDiscountCodeInfo,
  validateDiscountCodeForFrozenFoodOrders,
  validateDiscountCodeForToGoOrders,
  validateDiscountCodeForFoodBoxOrders,
  getDiscountCodeInfoById,
  getUserDiscountUsageByType,
  getSignUpHistory,
}

function loadSeasonalDiscountCodes(filter: DiscountCodeSearchFilter) {
  return apiService.get<DiscountCodeSearchResults>("DiscountCodes", undefined, undefined, filter);
}

function getDiscountCode(id: string) {
  return apiService.get<DiscountCode>("DiscountCodes", undefined, [id])
    .then(val => {
      val.expiryDateUtc = val && val.expiryDateUtc && new Date(val.expiryDateUtc);
      val.applicableToDate = val && val.applicableToDate && new Date(val.applicableToDate);
      val.applicableFromDate = val && val.applicableFromDate && new Date(val.applicableFromDate);
      return val;
    });
}

function DeleteDiscountCode(id: string) {
  return apiService.delete("DiscountCodes", undefined, [id]);
}

function updateDiscountCode(data: DiscountCode) {
  return apiService.put<EntitySaveResult>("DiscountCodes", undefined,
    {
      ...data,
      applicableFromDate: data.applicableFromDate && moment(data.applicableFromDate).format(DATE_FORMAT_ISO),
      applicableToDate: data.applicableToDate && moment(data.applicableToDate).format(DATE_FORMAT_ISO)
    });
}

function createDiscountCode(data: DiscountCode) {
  return apiService.post<EntitySaveResult>("DiscountCodes", undefined,
    {
      ...data,
      applicableFromDate: data.applicableFromDate && data.applicableFromDate.toDateString(),
      applicableToDate: data.applicableToDate && data.applicableToDate.toDateString()
    });
}

function getDiscountCodeInfo(code: string, isFirstOrder: boolean, paymentOption: PaymentOption = PaymentOption.Upfront, email?: string) {
  return apiService.get<DiscountCodeInfo>('DiscountCodes', 'Info', [code, isFirstOrder.toString(), paymentOption.toString(), email || '']);
}

function getValidatedDiscountCodeInfo(code: string, packageId?: string, paymentOption: PaymentOption = PaymentOption.Upfront, extraIds?: string[], email?: string, orderCreatedDate?: Date) {
  return apiService.get<DiscountCodeInfo>('DiscountCodes', `${code}/Info/Validate`, undefined, {
    paymentOption: paymentOption.toString(),
    packageId: packageId,
    extraIds,
    email: email,
    orderCreatedDate: orderCreatedDate && orderCreatedDate.toISOString()
  });
}

function getDiscountCodeInfoById(id: string, isFirstOrder: boolean, paymentOption: PaymentOption = PaymentOption.Upfront, email?: string) {
  return apiService.get<DiscountCodeInfo>('DiscountCodes', 'Info/Id', [id], { isFirstOrder: isFirstOrder.toString(), paymentOption: paymentOption.toString(), email: email || '' });
}

function getDiscountValueForAmount(info: DiscountCodeInfo, amount: number) {
  let discount = 0;
  if (info.valid) {
    discount = info.discountAmount > 0 ? info.discountAmount : amount * (info.discountPercentage / 100);
  }

  return Number(discount.toFixed(2));
}

function getEmptyDiscountCodeObj(type: DiscountCodeType, waiverType: any, featureRegistry?: FeatureRegistry) {
  let obj: DiscountCode = {
    code: '',
    discountAmount: 0,
    discountPercentage: 0,
    foodBoxDiscountValue: 0,
    firstTimeOrderOnly: false,
    orderPlacementOnly: true,
    inactive: false,
    loyaltyTokensOffer: 0,
    tokenEncashable: false,
    type: type,
    platform: DiscountCodePlatform.Any,
    waiverType: 0,
    discountCategory: undefined,
    applicablePaymentOption: (featureRegistryService.isFeatureEnabled(FeaturGroupCodes.WeeklyPayment, featureRegistry)) ? 2 : 0,
    salesChannel: type === DiscountCodeType.Seasonal ? CustomerOnBoardedType.Online : undefined
  }

  return obj;
}

function getDiscountCodeUsageListById(discountCodeId: string) {
  return apiService.get<DiscountCodeUsageDto[]>("DiscountCodes", "Usage", [discountCodeId]);
}

async function updateTokensByType(val: UpdateTokensFormValues) {
  return await apiService.patch("DiscountCodes", "UpdateTokens", val, undefined, undefined, true);
}

function validateDiscountCodeApplicability(code: string, packageCode: string) {
  return apiService.get('DiscountCodes', "Validate", [code, packageCode]);
}

function validateDiscountCodeForFrozenFoodOrders(code: string) {
  return apiService.get<boolean>('DiscountCodes', "Validate/FrozenFood", [code], {}, true);
}

function validateDiscountCodeForToGoOrders(code: string) {
  return apiService.get<boolean>('DiscountCodes', "Validate/ToGo", [code], {}, true);
}

function validateDiscountCodeForFoodBoxOrders(code: string, userId?: string) {
  return apiService.get<boolean>('DiscountCodes', `${code}/${MainOrderType.FoodBox}/Validate`, undefined, { userId });
}

function getUserDiscountUsageByType(userId: string, type: DiscountCodeType) {
  return apiService.get<DiscountCodeUsageDto[]>('DiscountCodes', `${userId}/Usage/${type}`);
}

function getSignUpHistory(userId: string, discountCodeTypes: DiscountCodeType[]) {
  return apiService.get<DiscountSignUpDetailsDto[]>('DiscountCodes', `${userId}/sign-ups`, undefined, { discountCodeTypes });
}